import { ChecklistTemplate, Measurement } from './checklist-template';
import { Contractor, Package, Project, Subpackage } from './entity';

export interface Checklist {
  id: string;
  project: Project;
  package: Package;
  subpackage: Subpackage;
  contractor: Contractor;
  checklistTemplateId: string;
  checklistTemplate: ChecklistTemplate;
  componentOrstedIdentifier: string;
  componentContractorIdentifier: string;
  checklistTypeName: string;
  checklistTypeShorthand: string;
  checklistStatus: keyof typeof ChecklistStatus;
  checklistTypeIsHandover: boolean;
  componentTypeName: string;
  componentTypeShorthand: string;
  totalChecklistItems: number;
  totalAdhocItems: number;
  totalSubRegularItems: number;
  okChecklistItemsCount: number;
  nokChecklistItemsCount: number;
  naChecklistItemsCount: number;
  location: Location;
  totalItemsCount: number;
  totalItemsNotInspected: number;
  regularInspectionGroups: ChecklistGroup[];
  adHocInspectionItems: AdHocInspectionItem[];
  signOffs: SignOff[];
  created: Date;
  updated: Date;
  updatedByUsername: string;
  projectId: string;
  projectName: string;
  contractorId: string;
  contractorShorthand: string;
  locationId: string;
  locationShorthand: string;
}

export interface PagedChecklist {
  data: Checklist[];
  pageSize: number;
  pageNumber: number;
  nextPage: string;
  previousPage: string;
}

export interface SignOff {
  supervisorName: string;
  supplierName: string;
  note: string;
  created: Date;
  updated: Date;
}

export interface AdHocInspectionItem {
  id: string;
  currentInspectionGrade: InspectionGrade;
  currentInspectionSeverity: InspectionSeverity;
  inspections: AdHocInspection[];
  itemCategory: ItemCategory;
  inspectionsCount: number;
}

export interface AdHocInspection {
  id: string;
  name: string;
  adHocItemReference: string;
  createdByUser: string;
  clientCreationTimestamp: Date;
  comment: string;
  inspectionGrade: InspectionGrade;
  inspectionItemId?: string;
  inspectionSeverity: InspectionSeverity;
  itemCategory: ItemCategory;
  created: Date;
  inspectionPhotoMetadata: AdHocInspectionPhotoMetadata[];
}

export interface AdHocInspectionPhotoMetadata {
  id: string;
  path: string;
  clientCreationTimestamp: Date;
}

export interface ChecklistGroup {
  id: string;
  name: string;
  items: ChecklistItem[];
}

export interface ChecklistItem {
  id: string; // guid
  itemId: string; // string, actually a number
  name: string;
  currentInspectionGrade: InspectionGrade;
  currentInspectionSeverity: InspectionSeverity;
  itemCategory: ItemCategory;
  inspections: RegularInspection[];
  inspectionsCount: number;
  subRegularItems: SubRegularItem[];
  measurements: Measurement[];
}

export interface RegularInspection {
  id: string;
  inspectionGrade: InspectionGrade;
  inspectionSeverity: InspectionSeverity;
  itemCategory: ItemCategory;
  createdByUser: string;
  comment: string;
  clientCreationTimestamp: Date;
  created: Date;
  inspectionDrawingMetadata: DrawingMetadata[];
  inspectionPhotoMetadata: InspectionPhotoMetadata[];
  inspectionMeasurementValues: InspectionMeasurementValues[][];
}

export interface SubRegularInspection {
  id: string;
  subRegularInspectionItemReference: string;
  name: string;
  createdByUser: string;
  clientCreationTimestamp: Date;
  inspectionGrade: InspectionGrade;
  inspectionSeverity: InspectionSeverity;
  itemCategory: ItemCategory;
  comment: string;
  created: Date;
  inspectionDrawingMetadata: DrawingMetadata[];
  inspectionPhotoMetadata: InspectionPhotoMetadata[];
  inspectionMeasurementValues: InspectionMeasurementValues[][];
}

export interface SubRegularItem {
  id: string;
  itemIdReference: string;
  name: string;
  currentInspectionGrade: InspectionGrade;
  currentInspectionSeverity: InspectionSeverity;
  inspections: SubRegularInspection[];
  inspectionsCount: number;
  itemCategory: ItemCategory;
}

export enum InspectionGrade {
  OK,
  NOK,
  NA,
}

export enum InspectionSeverity {
  Minimum = 'Minimum',
  Medium = 'Medium',
  Serious = 'Serious',
  Critical = 'Critical',
  Urgent = 'Urgent',
}

export enum InspectionType {
  Regular = 1,
  AdHoc,
  SubRegular,
  GeneralObs,
}

declare global {
  interface String {
    toNewSeverityStatus(): string;
  }
}

String.prototype.toNewSeverityStatus = function (): string {
  if (String(this) === InspectionSeverity.Minimum) {
    return 'Severity 1';
  } else if (String(this) === InspectionSeverity.Medium) {
    return 'Severity 2';
  } else if (String(this) === InspectionSeverity.Serious) {
    return 'Severity 3';
  } else if (String(this) === InspectionSeverity.Critical) {
    return 'Severity 4';
  } else if (String(this) === InspectionSeverity.Urgent) {
    return 'Severity 5';
  }

  return String(this);
};

export interface DrawingMetadata {
  id: string;
  path: string;
  clientCreationTimestamp: Date;
}

export interface InspectionPhotoMetadata {
  id: string;
  path: string;
  clientCreationTimestamp: Date;
}

export interface InspectionMeasurementValues {
  value: number;
  name: string;
  shorthand: string;
  additionalInformation: string;
  type: string;
  unit: string;
  checklistTemplateItemMeasurementId: string;
  checklistTemplateItemId: string;
  inspectionId: string;
}

export interface Location {
  id: string;
  shorthand: string;
  country: string;
  city: string;
}

export interface NewUnionRegularAdHocInspectionInputModel {
  inspectionType: InspectionType;
  name: string;
  adhocItemReference?: string;
  checklistTemplateGroupId?: string;
  checklistTemplateItemId?: string;
  clientCreationTimestamp: Date;
  inspectionGrade: InspectionGrade;
  inspectionSeverity: InspectionSeverity;
  comment: string;
  inspectionPhotoMetadataCopy?: InspectionMediaMetadataCopyInputModel;
  inspectionDrawingMetadataCopy?: InspectionMediaMetadataCopyInputModel;
  createdByUser: string;
  subChecklistItemReference?: string;
  itemCategoryId?: string;
  inspectionMeasurementValues?: InspectionMeasurementValues[][];
}

export interface InspectionMediaMetadataCopyInputModel {
  sourceInspectionId: string;
  sourceMetadataIds: string[];
}

export interface NewInspectionPhotoMetadataInputModel {
  id: string;
  clientCreationTimestamp: Date;
}

export interface NewInspectionDrawingMetadataInputModel {
  checklistTemplateItemDrawingMetadataId: string;
  clientCreationTimestamp: Date;
  drawing: File;
  drawingContent?: any;
}
export interface ItemCategory {
  id: string;
  name: string;
  shorthand: string;
}

export interface ChecklistDetailReInspectViewModel {
  inspectionType: InspectionType;
  checklistTemplateGroupId: string;
  checklistTemplateItemId: string;
  checklistId: string;
  inspectionId: string;
  newInspectionId: string;
  itemName: string;
  comment: string;
  inspectionStatus: InspectionGrade;
  inspectionSeverity: InspectionSeverity;
  itemReferenceId: string;
  inspectionPhotoMetadata: ChecklistDetailReInspectPhotoViewModel[];
  inspectionPhotoMetadataCopy?: InspectMediaCopyViewModel[];
  inspectionDrawingMetadata: ChecklistDetailReInspectDrawingViewModel[];
  inspectionDrawingMetadataCopy?: InspectMediaCopyViewModel[];
  itemCategoryId: string;
  inspectionMeasurementValues?: InspectionMeasurementValues[][];
}

export interface ChecklistDetailReInspectPhotoViewModel {
  id: string;
  clientCreationTimestamp: Date;
  photo: File;
}

export interface InspectMediaCopyViewModel {
  id: string;
  photo: string;
}

export interface ChecklistDetailReInspectDrawingViewModel {
  id: string;
  checklistTemplateItemDrawingMetadataId: string;
  clientCreationTimestamp: Date;
  drawing: File;
}

export enum ChecklistStatus {
  Open = 'Open',
  Completed = 'Completed',
  Deleted = 'Deleted',
}
