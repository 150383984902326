import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthorizationService } from '@app/services/authorization/authorization.service';
import { NotificationService } from '@app/services/notification.service';
import { AppConfig } from '@config';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions } from '@shared/ngx-gallery';
import { localeDateTime } from '@utils/date';
import { getFileBlobUrl } from '@utils/file';

import { GalleryService } from '../gallery-regular/gallery.service';

@Component({
  selector: 'app-gallery-observation',
  templateUrl: './gallery-observation.component.html',
  styleUrls: ['./gallery-observation.component.scss'],
})
export class GalleryObservationComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];

  assignmentId = this.route.snapshot.params.assignmentId;
  inspectionId = this.route.snapshot.params.inspectionId;
  includePrevious = false;
  photoUrls: NgxGalleryImage[] = [];
  isLoadCompleted = false;

  constructor(
    private route: ActivatedRoute,
    private galleryService: GalleryService,
    public authorizationService: AuthorizationService,
    private appConfig: AppConfig,
    private notificationService: NotificationService
  ) {
    this.route.queryParams.subscribe(params => {
      this.includePrevious = params.previous || false;
    });
  }

  isLoaded() {
    return this.isLoadCompleted;
  }

  ngOnInit() {
    this.galleryService.getObservationInspectionPhotos(this.assignmentId, this.inspectionId, this.includePrevious).subscribe({
      next: result =>
        result.forEach(image => {
          this.photoUrls.push({
            big: getFileBlobUrl(image.path),
            medium: getFileBlobUrl(image.path),
            small: getFileBlobUrl(image.path),
            description: 'Timestamp: ' + localeDateTime(image.clientCreationTimestamp),
          });
          this.isLoadCompleted = true;
        }),
      error: error => {
        this.displayErrorMessage(error);
        this.isLoadCompleted = true;
      },
    });

    this.galleryOptions = [
      {
        width: '1200px',
        height: '600px',
        imageAnimation: NgxGalleryAnimation.Fade,
        imageSize: NgxGalleryImageSize.Contain,
        imageDescription: true,
        thumbnails: false,
      },
    ];
  }

  private displayErrorMessage(error: any): void {
    if (error.status === 401) {
      this.notificationService.showNotification([{ text: 'Not Authorized.', status: 'error' }], 5000);
    } else if (error.status === 404) {
      this.notificationService.showNotification([{ text: 'Resource Not Found.', status: 'error' }], 5000);
    }
  }
}
