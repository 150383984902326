import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarComponent } from '@shared/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  showNotification(msgData: any[], duration = 2000) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: msgData,
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'snackbar',
    });
  }

  success(message: string, duration = 2000, dismissable = false) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: [{ text: message, status: 'success', dismissable }],
      duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'snackbar',
    });
  }

  error(message: string, duration = 2000, dismissable = false) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: [{ text: message, status: 'error', dismissable }],
      duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'snackbar',
    });
  }
}
