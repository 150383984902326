import { fileTypeFromBlob } from 'file-type';

import { environment } from '@env';

/**
 * Validate file from a list of allowed file types
 */
export async function validateFile(file: File, allowedFileTypes: string[]) {
  const fileType = await fileTypeFromBlob(file);
  return allowedFileTypes.includes(fileType.mime);
}

export function getFileBlobUrl(path: string) {
  if (path === '' || path == null) throw new Error('File path is required');

  const url = `${environment.storageUrl}${path}`;

  if (environment.apiKey && environment.apiKey !== '') {
    if (url.includes('?')) {
      return `${url}&apiKey=${environment.apiKey}`;
    }

    return `${url}?apiKey=${environment.apiKey}`;
  }

  return url;
}
