import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { map, Subject } from 'rxjs';

import { UserService } from '@features/assignment/user-assignment/user.service';

import * as authorizationMappings from '../../../../assets/auth/authorization.mappings.json';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private roleIdentifier = 'roles';
  private _roles$ = new Subject<string[]>();

  constructor(private msalService: MsalService, private userService: UserService) {
    if (this.authenticated) {
      this.userService.getAdUser(this.username).subscribe(userDetails => {
        this._roles$.next(userDetails.roles.map(atob));
        this.saveRoles(userDetails.roles);
      });
    }
  }

  get authenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  get username() {
    if (!this.authenticated) {
      return null;
    }

    const activeAccount = this.msalService.instance.getActiveAccount();

    if (activeAccount) {
      return activeAccount.username;
    }

    const accounts = this.msalService.instance.getAllAccounts();
    this.msalService.instance.setActiveAccount(accounts[0]);

    return this.msalService.instance.getActiveAccount().username;
  }

  private getAuthorizationMapping(): Record<string, string[]> {
    return authorizationMappings;
  }

  saveRoles(arrRoles: string[]) {
    const roles = arrRoles.map(atob);

    const encodedRoles = btoa(JSON.stringify(roles));
    sessionStorage.setItem(this.roleIdentifier, encodedRoles);
  }

  private getSavedRoles() {
    const rolesText = sessionStorage.getItem(this.roleIdentifier);
    if (rolesText === undefined || rolesText === null) {
      return null;
    }

    const decodedRoles = atob(rolesText);
    return JSON.parse(decodedRoles) as string[];
  }

  isAuthorized(element: string) {
    const roles = this.getSavedRoles();

    return this._isAuthorized(element, roles);
  }

  isAuthorized$(element: string) {
    return this._roles$.pipe(map(roles => this._isAuthorized(element, roles)));
  }

  private _isAuthorized(element: string, roles: string[]) {
    const elementText = element.toLowerCase();
    const mappings = this.getAuthorizationMapping();

    if (!roles || roles.length === 0) {
      return false;
    }

    if (mappings[elementText] === undefined) {
      return false;
    }

    if (mappings[elementText].includes('*')) {
      return true;
    }

    for (const role of roles) {
      for (const mappedRole of mappings[elementText]) {
        if (mappedRole.toLowerCase() === role.toLowerCase()) {
          return true;
        }
      }
    }

    return false;
  }

  isAdminAuthorized() {
    return this.isAuthorized('admins');
  }

  hasSessionRole() {
    const roles = this.getSavedRoles();
    return !(roles === undefined || roles === null);
  }
}
