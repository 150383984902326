import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgxGalleryModule } from '@shared/ngx-gallery';

import { GalleryAdhocComponent } from './gallery-adhoc/gallery-adhoc.component';
import { GalleryObservationComponent } from './gallery-observation/gallery-observation.component';
import { GalleryRegularComponent } from './gallery-regular/gallery-regular.component';

@NgModule({
  imports: [RouterModule, NgIf, NgxGalleryModule],
  declarations: [GalleryRegularComponent, GalleryAdhocComponent, GalleryObservationComponent],
  providers: [],
})
export class GalleryModule {}
